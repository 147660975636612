<template>
  <div>
    <q-btn
      class="color-mode-toggle-btn"
      size="sm"
      flat
      unelevated
      :icon="'svguse:/icons.svg#' + modeIconId"
      @click="toggleMode"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { useQuasar } from "quasar";

export default {
  name: "ModeToggle",
  setup() {
    const $q = useQuasar()
    let modeIconId = ref($q.dark.isActive ? 'sun' : 'moon')
    const toggleMode = () => {
      $q.dark.toggle()
      modeIconId.value = $q.dark.isActive ? 'sun' : 'moon'
    }

    return {
      toggleMode,
      modeIconId
    }
  }
}
</script>