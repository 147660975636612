<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { useQuasar } from 'quasar'
import { watch } from 'vue'

export default {
  setup() {
    const $q = useQuasar()

    $q.dark.set(JSON.parse(localStorage.getItem('isDark')))

    watch(() => $q.dark.isActive, val => {
      localStorage.setItem('isDark', JSON.stringify($q.dark.isActive))
    })
  }
}
</script>

<style lang="sass">
  @import "styles/style"
</style>