import {createRouter, createWebHistory} from 'vue-router'
import DefaultLayout from "../layouts/DefaultLayout";

const routes = [
  {
    path: '/',
    name: 'DefaultLayout',
    component: DefaultLayout,
    //redirect: '/blocks',
    //'/address/Ux12b6f5eaabc1423b4332794caf75cdcf895c1a3e',

    children: [
      {
        path:"/",
        name: "blocks",
        component: () => import('../views/pages/MainPage/MainPage.vue')
      },
      {
        path: '/blocks/:block',
        name: 'block',
        props: true,
        component: () => import('../views/pages/BlockPage/BlockPage')
      },
      {
        path: '/address/:address',
        name: 'address',
        props: true,
        component: () => import('../views/pages/AddressPage/AddressPage')
      },
      {
        path: '/transactions/:tx',
        name: 'tx',
        props: true,
        component: () => import('../views/pages/TransactionPage/TransactionPage')
      },
      {
        path: '/token/:token',
        name: 'token',
        props: true,
        component: () => import('../views/pages/TokenPage/TokenPage')
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        props: true,
        component: () => import('../views/pages/NotFoundPage/NotFoundPage')
      }
    ]
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
