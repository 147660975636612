import { createStore } from 'vuex'

export default createStore({
  state: {
    tokenHoldersCurrentPageSize: 0,
    tokenTransfersCurrentPageSize: 0
  },
  mutations: {
    changeTokenHoldersCurrentPageSize (state, newCount) {
      state.tokenHoldersCurrentPageSize = newCount
    },
    changeTokenTransfersCurrentPageSize (state, newCount) {
      state.tokenTransfersCurrentPageSize = newCount
    }
  },
  actions: {
  },
  modules: {
  }
})
