<template>
  <q-form
    :class="`searchbar ${open ? 'open' : ''}`"
    :style="`${fullWidth ? 'width: 100% !important' : ''}`"
    @submit="onSubmit"
  >
    <q-input
      v-model="search"
      :placeholder="placeholder"
      borderless
      clearable
      clear-icon="clear"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      <template v-slot:prepend>
        <q-icon
          name="search"
          class="searchbar-icon"
        />
      </template>
    </q-input>
  </q-form>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Searchbar",
  props: {
    open: Boolean,
    fullWidth: Boolean,
    placeholder: String
  },
  emits: ['focus', 'blur'],
  setup(props, ctx) {
    const router = useRouter()

    ctx.emit('focus')
    ctx.emit('blur')

    const search = ref(null)
    const fieldOpen = ref(false)
    return {
      search,
      fieldOpen,

      onSubmit() {
        if (search.value) {
          if (search.value.length < 10) {
            router.push({
              name: 'token',
              params: {
                token: search.value.trimStart().trimEnd()
              }
            })
          }
          else if ((search.value.length === 40) || (search.value.length === 42)) {
            const hash = search.value.replace('Ux', '')
            router.push({
              name: 'address',
              params: {
                address: hash.trimStart().trimEnd()
              }
            })
          }
          else {
            router.push({
              name: 'block',
              params: {
                block: search.value.trimStart().trimEnd()
              }
            })
          }
        }
        search.value = ''
      }
    }
  }
}
</script>