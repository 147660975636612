<template>
  <q-layout view="lHh Lpr lFf">
    <q-header>
      <q-toolbar
        class="text-primary bg-toolbar"
        style="height: 60px"
      >
        <router-link
          to="/"
          class="link"
        >
          <q-avatar square>
            <img
              src="../assets/logo.svg"
              alt="UBIX.EXPLORER"
            >
          </q-avatar>
        </router-link>
        <q-toolbar-title
          shrink
          class="logo-text"
        >
          <router-link
            to="/"
            class="link text-black"
          >UBIX Explorer</router-link>
        </q-toolbar-title>
        <q-space />
        <Searchbar
          placeholder="Search: address, block, token"
          :open="searchbarOpen"
          @focus="searchbarOpen = true"
          @blur="searchbarOpen = false"
        />
        <ModeToggle />
      </q-toolbar>
    </q-header>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import Searchbar from "../views/components/Searchbar/Searchbar";
import ModeToggle from "../views/components/ModeToggle/ModeToggle";

export default {
  name: "LayoutDefault",
  components: { ModeToggle, Searchbar },
  setup() {
    const searchbarOpen = ref(false)
    const toggleSearchbar = () => searchbarOpen.value = !searchbarOpen.value
    return {
      searchbarOpen,
      toggleSearchbar
    };
  },
};
</script>
<style lang="sass">
  @media screen and (max-width: 726px)
    .logo-text
      display: none
</style>
